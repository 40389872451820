import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Input,
  Label,
  Modal,
  FormGroup,
  Row,
  Alert,
  Button,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import React, { useState, useEffect } from "react";
import axios from "axios";
import userDummy from "../../assets/images/users/user-dummy-img.jpg";
import { ToastContainer, toast } from "react-toastify";
import { get_cookie } from "../../helpers/get_cookie";
import Loader from "../../Components/Common/Loader";
import forge from "node-forge";
import { tipalti_sandbox, tipalti_production } from "../../config";
import Select from "react-select";

const InstantPayouts = () => {
  document.title = "Instant Payouts | Get Rostered";
  const [utcTime, setUtcTime] = useState("");
  const [payerName] = useState("GETROSTERED");
  const [payeeId, setPayeeId] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [iframeURL, setIframeURL] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [selectedBank, setSelectedBank] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [sysSwiftCodes, setSysSwiftCodes] = useState([]);
  const [copyState, setCopyState] = useState("copy");
  const [errorMessage, setErrorMessage] = useState("");
  const [workforceData, setWorkforceData] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [hoverIcon, setHoverIcon] = useState(false);
  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [email, setEmail] = useState("");

  const authUser = JSON.parse(get_cookie("workauthUser")).user_id;
  const masterKey = tipalti_sandbox.MASTER_KEY;
  // const masterKey = tipalti_production.MASTER_KEY;

  const generateUTCTime = () => {
    const utcTimestamp = Math.floor(new Date().getTime() / 1000);
    setUtcTime(utcTimestamp);
  };

  const loadData = () => {
    axios
      .get("/get-swift-codes")
      .then((res) => {
        setSysSwiftCodes(res);
        console.log(res, "res1234");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const generateIframe = () => {
    if (!isUserDataLoaded) {
      console.log("User data is not yet loaded, cannot generate iframe.");
      return;
    }
    const baseURL = tipalti_sandbox.BASE_URL + `payeedashboard/home`;
    // const baseURL = tipalti_production.BASE_URL + `payeedashboard/home`;
    

    const parameters = [
      `payer=${payerName}`,
      `idap=${authUser}`,
      `payeeType=Individual`,
      firstName && `first=${firstName}`,
      lastName && `last=${lastName}`,
      `forceCountry=Australia`,
      email && `email=${email}`,
      city && `city=${city}`,
      zip && `zip=${zip}`,
      `ts=${utcTime}`,
    ];
  
    const compiledParameters = parameters.filter(Boolean).join("&");

    const hmac = forge.hmac.create();
    hmac.start("sha256", masterKey);
    hmac.update(compiledParameters);
    const hashText = hmac.digest().toHex();

    const finalIframeURL = `${baseURL}?${compiledParameters}&hashkey=${hashText}`;
    setIframeURL(finalIframeURL);
    updateInstantPayoutsStatus();
  };

  const updateInstantPayoutsStatus = () => {
    axios
      .post("/update-instantpayouts-status", {
        user_id: authUser,
      })
      .then((res) => {
        // Check if the response status is OK (HTTP 200)
        if (res.message === "Success") {
          console.log("Payee updated successfully.");
        } else {
          console.log("Payee not updated. Error:", res.error);
          // toast.error("Failed to update payee ID.");
        }
      })
      .catch((err) => {
        console.error("Error updating instant payouts status:", err);
        // toast.error("Error updating payee ID status.");
      });
  };



  useEffect(() => {
    loadData();
    getworkforceData();
    generateUTCTime();
  }, [authUser]);


  useEffect(() => {
    console.log(authUser, masterKey, utcTime, "AUTHIUSERTIME");
    if (authUser && masterKey && utcTime) {
      console.log("AUTHUSER");
      generateIframe();
      updateInstantPayoutsStatus();
    }
  }, [authUser, masterKey, utcTime]);

  useEffect(() => {
    if (isUserDataLoaded) {
      generateIframe();
    }
  }, [isUserDataLoaded]);

  const getworkforceData = () => {
    axios
      .post("/get-workforce-data", { user_id: authUser })
      .then((res) => {
        const workforceData = res.workforce_data;
        const workUserData = res.userData;
        setWorkforceData(workforceData);

         // Set required fields
      setFirstName(workforceData.first_name || "");
      setLastName(workforceData.last_name || "");
      setCity(workforceData.suburb || "");
      setZip(workforceData.postcode || "");
      setEmail(workUserData.email || "");

      // Mark data as loaded
      setIsUserDataLoaded(true);

      console.log('First Name:',workforceData.first_name,'Last Name:',workforceData.last_name,'Email:',workUserData.email,'City:',workforceData.suburb,'Pincode:',workforceData.pincode);
      })
      .catch((error) => {
        console.error(error);
        // Mark data as loaded
      setIsUserDataLoaded(false);
      });
  };

  // Check if the user is coming back to the tab
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        const currentUtcTimestamp = Math.floor(new Date().getTime() / 1000);
        if (currentUtcTimestamp - utcTime > 360) {
          // 10 minutes
          toast.info("Session expired. Refreshing...");
          generateUTCTime(); // Refresh the timestamp
          generateIframe(); // Regenerate the iFrame URL
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [utcTime]);

  // Handle bank selection
  const handleBankChange = (selectedOption) => {
    setSelectedBank(selectedOption);
    setErrorMessage("");
    console.log(sysSwiftCodes);
    // Find the swift code for the selected bank
    const selected = sysSwiftCodes.find(
      (bank) => bank.bank_name === selectedOption.value
    );
    console.log(selected);
    setSwiftCode(selected ? selected.swift_code : "");
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    setErrorMessage("");
  };

  // Copy swift code to clipboard
  const copyToClipboard = () => {
    if (swiftCode) {
      navigator.clipboard.writeText(swiftCode);
      setCopyState("copied"); // Change to copied state

      // Reset to copy state after 2 seconds
      setTimeout(() => {
        // setSelectedBank("");
        // setSwiftCode("");
        setCopyState("copy");
      }, 2000);
    } else {
      setErrorMessage("Please select a bank to copy the SWIFT Code.");
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Instant Payouts" />

          <Row>
            <Col xl={12}>
              <Card className="mb-0" style={{ margin: "0 auto" }}>
                <CardHeader className="d-flex justify-content-between align-items-center">
                  {!workforceData && (
                    <Alert color="info" className="mb-0">
                      <strong>Loading</strong> ...
                    </Alert>
                  )}
                  {workforceData && workforceData.ip_enabled === "Y" && (
                    <Alert color="success" className="mb-0">
                      <strong>Success!</strong> Setup complete. Payments will be
                      processed.
                    </Alert>
                  )}

                  {workforceData && workforceData.ip_enabled === "N" && (
                    <Alert color="danger" className="mb-0">
                      <strong>Warning!</strong> Setup incomplete. Payments
                      cannot be processed.
                    </Alert>
                  )}

                  <div className="d-flex justify-content-end align-items-center gap-2">
                    {/* <i
                      className="ri-information-fill fs-5"
                      style={{ marginRight: "5px" }}
                    ></i> */}
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                      }}
                      onMouseEnter={() => setHoverIcon(true)}
                      onMouseLeave={() => setHoverIcon(false)}
                    >
                      <i
                        className="ri-information-fill fs-5"
                        style={{ marginRight: "5px", cursor: "pointer" }}
                      ></i>

                      {/* Tooltip appears when hovering over the icon */}
                      <span
                        style={{
                          visibility: hoverIcon ? "visible" : "hidden", // Show tooltip on hover
                          position: "absolute",
                          top: "50%", // Vertically center the tooltip
                          left: "100%", // Position the tooltip to the right of the icon
                          marginLeft: "10px", // Space between icon and tooltip
                          transform: "translateY(-50%)", // Adjust vertical alignment
                          backgroundColor: "white",
                          color: "#021d2d",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          border: "1px solid #ffbc00",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                          fontSize: "12px",
                          whiteSpace: "nowrap",
                          zIndex: 1,
                        }}
                      >
                        Copy SWIFT Code for payments. Make sure to use the
                        correct code when transferring.
                      </span>
                    </div>
                    Click the Copy SWIFT Code button on the right to copy the
                    swift code.
                    <Button
                      className="btn-soft-dark"
                      onClick={toggleModal}
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                      style={{
                        backgroundColor: hover ? "#ffda7d" : "#ffbc00",
                        color: "#000000",
                        borderColor: hover ? "#ffda7d" : "#ffbc00",
                      }}
                    >
                      Copy SWIFT Code
                    </Button>
                  </div>
                </CardHeader>

                <CardBody className="d-flex justify-content-between align-items-center gap-3"></CardBody>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
            <ModalHeader toggle={toggleModal}>SWIFT Code</ModalHeader>
            <ModalBody>
              <Row>
                <Col xl={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <FormGroup style={{ flex: 1 }}>
                      <Label for="bankSelect">Select Bank</Label>
                      <Select
                        value={selectedBank}
                        id="bankSelect"
                        onChange={handleBankChange}
                        options={sysSwiftCodes?.map((code) => ({
                          value: code.bank_name,
                          label: code.bank_name,
                        }))}
                        placeholder="Select a bank"
                      />
                      {errorMessage && (
                        <div
                          style={{
                            position: "absolute",
                            bottom: "0",
                            left: "0",
                            color: "#e13c3c",
                            fontSize: "0.875em",
                            marginLeft: "1.5em",
                            marginBottom: "0%",
                          }}
                        >
                          {errorMessage}
                        </div>
                      )}
                    </FormGroup>

                    <FormGroup style={{ flex: 1 }}>
                      <Label for="swiftCode">SWIFT Code</Label>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Input
                          type="text"
                          id="swiftCode"
                          value={swiftCode}
                          disabled
                          style={{ marginRight: "8px" }}
                        />
                        <button
                          type="button"
                          onClick={copyToClipboard}
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {copyState === "copy" ? (
                            <i
                              className="bx bxs-copy"
                              style={{ color: "#ffbc00" }}
                            ></i>
                          ) : (
                            <i
                              className="ri-check-fill"
                              style={{ color: "#00cb81" }}
                            ></i>
                          )}
                          <span style={{ marginLeft: "5px" }}>
                            {copyState === "copy" ? "Copy" : "Copied!"}
                          </span>
                        </button>
                      </div>
                    </FormGroup>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          <Row>
            <Col xl={12}>
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  paddingBottom: "56.25%", // Maintain a 16:9 aspect ratio
                  height: 0,
                  overflow: "hidden",
                }}
              >
                {iframeURL && (
                  <iframe
                    id="liveExample"
                    src={iframeURL}
                    title="iFrame Example"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      border: "none",
                    }}
                  />
                )}
              </div>
              <ToastContainer />

              {isLoading && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 9999,
                  }}
                >
                  <Loader />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InstantPayouts;
